
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Home from '@screens/home';

const HomePage = () => {
  return (
    <Home />
  );
};

export default HomePage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  